import { WritableAtom, atom } from 'nanostores'
import { Content, NullContent } from 'common/src/types'
import { simplifyString, simpleString2array } from 'common/src/utils'
import { ManagedDimensions } from 'common/src/managed-dimensions'
import { State } from '../../../../../store/state'
import { ArticleKind } from '../../../../../store/kind'
import { configData } from './config/seo-rules'

export const SEO_FOCUS_WORDS_SECTION = 'SEO Focus Words Analysis'
export const SEO_SECTION = 'SEO Analysis'
export const READABILITY_SECTION = 'Readability Analysis'
export const totalScore = atom<number>(0)
export interface AnalysisData {
  label: string
  isValid: boolean
  score: number
  defaultScore: number
  enabled: boolean
}
export interface Section {
  title: string
  analysisData: AnalysisData[]
}
export class SEOTab {
  seoFocusWordsAtom = atom<string>('')
  currentMetadata: any = []
  content: Content = new NullContent()
  seoSections: WritableAtom<Section[]> = atom([])
  totalScoreSum: number = 0

  internalDomains: any | undefined = undefined
  favoritesDomains: any | undefined = undefined

  constructor(private state: State) {
    this.internalDomains = SEOTab.parseDomains(process.env.SEO_INTERNAL_DOMAINS)
    this.favoritesDomains = SEOTab.parseDomains(process.env.SEO_FAVORITES_DOMAINS)
    this.loadSections()
  }
  private seoConfig: any = configData

  private static parseDomains(envVariable?: string) {
    if (!envVariable) {
      return {}
    }
    return envVariable.split('|').map((domain) => new URL(domain.trim()).hostname)
  }

  public getFieldsWithFallback() {
    return {
      titleFallback: this.content.seoTitle || this.content.headline,
      descriptionFallback: this.content.seoDescription || this.content.lead,
    }
  }

  public processArticleContent(content: Content) {
    if (content.type !== ArticleKind.type) {
      return
    }

    this.content = content
    this.currentMetadata = this.getMetadata(this.content)
    this.getAnalysisStatus(content.seoFocusWords)
  }

  build(data: any, isValid: boolean, isWarning = false) {
    return {
      label: data?.rule || '',
      isValid,
      isWarning,
      score: isValid ? data?.score || 0 : 0,
      defaultScore: data?.score || 0,
      enabled: data?.enabled || false,
    }
  }

  private section(type: string): AnalysisData[] {
    const analysisData: AnalysisData[] = []

    for (const data in this.seoConfig) {
      const config = this.seoConfig[data]

      if (config.type === type) {
        analysisData.push(this.build(config, false))
        if (config.enabled === true) {
          this.totalScoreSum += config.score
        }
      }
    }
    return analysisData
  }

  public loadSections() {
    const seoSections: Section[] = [
      {
        title: SEO_FOCUS_WORDS_SECTION,
        analysisData: this.section('words'),
      },
      {
        title: SEO_SECTION,
        analysisData: this.section('general'),
      },
      {
        title: READABILITY_SECTION,
        analysisData: this.section('read'),
      },
    ]

    this.seoSections.set(seoSections)
  }

  onInputChange = (words: string) => {
    if (!this.content) {
      return
    }
    this.updateSEOStatus(words)
    this.state.editorShell.updateContent('seoFocusWords')(words)
    this.content.seoFocusWords = words
  }
  updateTotalScore(sections: Section[]) {
    let total = 0

    sections.forEach((section) => {
      section.analysisData.forEach((data) => {
        total += data.score || 0
      })
    })

    const percentage = (total / this.totalScoreSum) * 100
    total = Math.round(percentage)

    totalScore.set(total)
  }

  processField(field: string) {
    if (!field) {
      return []
    }
    return field
      .replace(/['"’,.!/?:&()><=]/g, ' ')
      .replace(/<[^>]+>/g, ' ')
      .replace(/nbsp;/g, '')
      .replace(/\s{2,}/g, ' ')
      .trim()
      .split(' ')
      .filter((item) => item.trim().length > 0)
      .map((item) => item.toLowerCase())
  }

  processWords(words: string) {
    return simpleString2array(words, ' ')
  }

  checkWordsLength(word: string, minValue: number, maxValue: number) {
    if (!word) {
      return false
    }

    const wordsArray = this.processWords(word)

    return wordsArray.length >= minValue && wordsArray.length <= maxValue
  }

  checkCharacterLength(word: string, minValue: number, maxValue: number) {
    const words = simplifyString(word)

    return words.length >= minValue && words.length <= maxValue
  }

  wordsInContent(field: string, seoWords: string) {
    if (!seoWords) {
      return {
        allWordsPresent: false,
        count: 0,
      }
    }

    const fieldArray = this.processField(field)
    const seoWordsArray = this.processWords(seoWords)

    let seoWordsCount = 0

    seoWordsArray.forEach((seoWord) => {
      seoWordsCount += fieldArray.filter((word) => word.toLowerCase() === seoWord.toLowerCase()).length
    })

    return {
      allWordsPresent: seoWordsArray.every((seoWord) =>
        fieldArray.some((fieldWord) => fieldWord.toLowerCase() === seoWord.toLowerCase())
      ),
      count: seoWordsCount,
    }
  }

  calculateSEODensity(seoWords: string) {
    if (!seoWords) {
      return 0
    }

    const currentContent = this.content

    const articleWords = this.processField(currentContent.text)
    const leadWords = this.processField(currentContent.lead)
    const headlineWords = this.processField(currentContent.headline)
    const seoWordsArray = this.processWords(seoWords).map((word) => word.toLowerCase())

    const totalWords = articleWords.length + leadWords.length + headlineWords.length
    const keywordCounts: any = {}
    let totalKeywords = 0

    seoWordsArray.forEach((word) => {
      keywordCounts[word] = 0
    })

    articleWords.forEach((word) => {
      if (keywordCounts[word] !== undefined) {
        keywordCounts[word]++
      }
    })
    leadWords.forEach((word) => {
      if (keywordCounts[word] !== undefined) {
        keywordCounts[word]++
      }
    })
    headlineWords.forEach((word) => {
      if (keywordCounts[word] !== undefined) {
        keywordCounts[word]++
      }
    })

    for (const word in keywordCounts) {
      totalKeywords += keywordCounts[word]
    }

    if (totalKeywords === 0 && totalWords === 0) {
      return 0
    }

    return (totalKeywords / totalWords) * 100
  }

  getEmbeds(text: any, embedType: string) {
    const articleDOM = new DOMParser().parseFromString(text, 'text/html')
    const oEmbedElements = articleDOM.querySelectorAll('[' + embedType + ']')

    let isEmbed = false
    oEmbedElements.forEach((link) => {
      if (link.getAttribute(embedType)) {
        isEmbed = true
      }
    })

    return isEmbed
  }
  getExternalLinks(text: any) {
    const articleDOM = new DOMParser().parseFromString(text, 'text/html')
    const link = articleDOM.querySelectorAll('a')

    let isExternal = false

    link.forEach((link) => {
      const href = link.getAttribute('href')
      if (!href) return
      if (!this.internalDomains) return

      try {
        const url = new URL(href)
        const hostname = url.hostname

        if (!this.internalDomains.includes(hostname)) {
          isExternal = true
        }
      } catch {
        return
      }
    })

    return isExternal
  }

  extractSEORule(field: string, data: any) {
    const isValid = this.checkCharacterLength(field, data.minLength, data.maxLength)
    const isWarning = this.checkCharacterLength(field, data.limitMin, data.limitMax)

    return this.build(data, isValid, isWarning)
  }

  extractLinksRule(field: any, data: any, type: string[]) {
    const linkCount = this.getArticleLinks(field, type).linksCount
    const isValid = linkCount >= 2

    return this.build(data, isValid, linkCount == 1)
  }
  getArticleLinks(text: any, type: string[]) {
    const articleDOM = new DOMParser().parseFromString(text, 'text/html')
    const linkObject = {
      isLink: false,
      linksCount: 0,
    }
    const el = articleDOM.querySelectorAll('a')

    el.forEach((link) => {
      const href = link.getAttribute('href')
      if (!href) return

      try {
        const url = new URL(href)
        const hostname = url.hostname

        if (!type.includes(hostname)) return

        linkObject.isLink = true
        linkObject.linksCount++
      } catch {
        return
      }
    })

    return linkObject
  }

  getArticleParagraphs(text: string) {
    const articleDOM = new DOMParser().parseFromString(text, 'text/html')
    const paragraphs = articleDOM.querySelectorAll('p')
    const h2Titles = articleDOM.querySelectorAll('h2')

    const readSet = {
      paragraphsInArticle: false,
      wordsInParagraph: false,
      paragraphsWithH2: false,
    }

    let paragraphCount = 0
    let wordsInParCount = 0

    paragraphs.forEach((paragraph) => {
      const wordsCount = this.processField(paragraph.innerText).length
      paragraphCount++
      if (wordsCount >= configData.wordsInParagraph.maxNumber) {
        wordsInParCount++
      }
    })

    readSet.paragraphsInArticle = paragraphCount >= configData.paragraphsInArticle.minNumb
    readSet.wordsInParagraph = paragraphCount > 0 && wordsInParCount === paragraphCount
    readSet.paragraphsWithH2 = h2Titles.length >= configData.paragraphsWithH2.minNumb

    return readSet
  }

  checkConsecutiveSentences(text: string): boolean {
    let nextTwoWords: any
    const sentences = text.split(/[.!?]/).filter((sentence) => sentence.trim() !== '')
    const firstWords = sentences.map((sentence) => sentence.trim().split(' ')[0].toLowerCase())

    if (sentences.length === 0) {
      return false
    } else {
      return !firstWords.some((word, index) => {
        nextTwoWords = [firstWords[index + 1], firstWords[index + 2]]
        return nextTwoWords.every((nextWord: any) => nextWord === word)
      })
    }
  }

  checkSentenceLength(text: string, data: any): boolean {
    const sentences = text.split(/[.!?]/).filter((sentence) => sentence.trim() !== '')

    if (sentences.length === 0) {
      return false
    } else {
      return sentences.every((sentence) => {
        const words = sentence.split(/\s+/).filter((word) => word.trim() !== '')
        return words.length <= data.maxValue
      })
    }
  }

  extractMetadataRule(field: string, data: any) {
    const isValid = this.metadataInArticle(field, data.minValue)
    const isWarning = isValid ? false : this.metadataInArticle(field, data.limitMin)

    return this.build(data, isValid, isWarning)
  }

  metadataInArticle(field: string, threshold: number) {
    let wordCount = 0

    const names = this.currentMetadata
      .flatMap((array: any) => (array ? array.map((obj: any) => obj.name.toLowerCase()) : []))
      .filter((name: any) => name)

    const fieldArray = this.processField(field)

    fieldArray.forEach((element) => {
      if (!names.includes(element)) return
      wordCount++
    })

    return wordCount >= threshold ? true : false
  }

  getMetadata(content: Content) {
    const metadata: any = []
    ManagedDimensions.getKeys().forEach((dimension) => {
      const actual = content?.[dimension] || []

      metadata.push(actual)
    })

    return metadata.filter(Boolean)
  }

  getReadabilityArray = () => {
    const readabilityLabels = [
      'paragraphsInArticle',
      'wordsInParagraph',
      'paragraphsWithH2',
      'consecutiveSentence',
      'sentenceLength',
    ]
    const text = this.content.text
    const validationFunctions: Record<string, () => boolean> = {
      consecutiveSentence: () => this.checkConsecutiveSentences(text),
      sentenceLength: () => this.checkSentenceLength(text, configData.sentenceLength),
    }

    const readabilityData = readabilityLabels.map((field) => {
      const isValid = validationFunctions[field]
        ? validationFunctions[field]()
        : (this.getArticleParagraphs(text) as any)[field]
      const theField = (configData as Record<string, any>)[field]
      return this.build(theField, isValid)
    })

    return readabilityData
  }

  getWordsAnalysisData = (seoFocusWords: string) => {
    if (!this.content || !this.content.text) {
      return []
    }
    const currentContent = this.content

    const kindFields = this.state.kindFromType(currentContent.type).seoAnalysisFields
    const { wordsDensity, wordsLength, wordsInIntro } = configData
    const seoDensity = this.calculateSEODensity(seoFocusWords)
    const isDensityValid = seoDensity >= wordsDensity.minValue && seoDensity <= wordsDensity.maxValue

    const isValid = this.checkWordsLength(seoFocusWords, wordsLength.minLength, wordsLength.maxLength)
    const articleIntro = currentContent.text.split(' ').slice(0, 30).join(' ')
    const allWordsPresent = this.wordsInContent(articleIntro, seoFocusWords).allWordsPresent

    const { titleFallback, descriptionFallback } = this.getFieldsWithFallback()

    const seoAnalysisData = [
      { ...this.build(wordsLength, isValid) },
      ...kindFields.map((name) => {
        const fieldToCheck =
          name === 'seoTitle'
            ? titleFallback
            : name === 'seoDescription'
              ? descriptionFallback
              : currentContent[name as any]
        const { allWordsPresent, count } = this.wordsInContent(fieldToCheck, seoFocusWords)

        const { limit, scope } = (configData as any)[name]
        return this.build(
          (configData as any)[name],
          allWordsPresent,
          (scope === 'serp' && count == limit) || (scope === 'warning' && allWordsPresent == limit)
        )
      }),
      { ...this.build(wordsInIntro, allWordsPresent) },
      { ...this.build(wordsDensity, isDensityValid) },
    ]

    return seoAnalysisData
  }

  getAnalysisStatus(seoFocusWords: string) {
    this.seoFocusWordsAtom.set(seoFocusWords)

    const currentSection = this.seoSections.get()
    const { titleFallback, descriptionFallback } = this.getFieldsWithFallback()

    currentSection.forEach((data: any, index: number) => {
      const node = currentSection[index]
      const { text } = this.content
      const {
        externalLinks,
        embeds,
        seoTitleLength,
        internalLinks,
        partnerLinks,
        seoDescriptionLength,
        metadata,
      } = configData

      switch (data.title) {
        case SEO_FOCUS_WORDS_SECTION:
          node.analysisData = this.getWordsAnalysisData(seoFocusWords)
          break
        case SEO_SECTION:
          node.analysisData = [
            this.extractSEORule(titleFallback, seoTitleLength),
            this.extractSEORule(descriptionFallback, seoDescriptionLength),
            this.extractLinksRule(text, internalLinks, this.internalDomains),
            this.extractLinksRule(text, partnerLinks, this.favoritesDomains),
            this.extractMetadataRule(text, metadata),
            {
              ...this.build(
                externalLinks,
                this.getEmbeds(text, 'data-oembed-url') || this.getExternalLinks(text)
              ),
            },
            { ...this.build(embeds, this.getEmbeds(text, 'p-smartembed')) },
          ]
          break
        case READABILITY_SECTION:
          node.analysisData = this.getReadabilityArray()
          break
      }
    })

    this.seoSections.set(currentSection)
    this.updateTotalScore(currentSection)
  }

  updateSEOStatus = (seoFocusWords: string) => {
    const currentSections = this.seoSections.get()
    currentSections.forEach((data: any, index: number) => {
      if (data.title === SEO_FOCUS_WORDS_SECTION) {
        currentSections[index].analysisData = this.getWordsAnalysisData(seoFocusWords)
      }
    })
    this.updateTotalScore(currentSections)
  }
}
